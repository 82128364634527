import React, { useState, useEffect } from "react";
import "./Beauty.css";
import items from "./dataBeauty";
import MenuBeauty from "./MenuBeauty";
import CategoriesBeauty from "./CategoriesBeauty";
import background from "./black-paper-3.jpg";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  ContentSec,
  ContentRow,
  TextWrapper,
  ContentButton,
  ContentColumn,
} from "./ContentStyles";
import { Container } from "../../globalStyles";
import { fresha_url } from "../../fresha";
import { lightYellow } from "../../Colors";
import { NavLinks } from "../Navbar/NavbarStyles";

const allCategories = [...new Set(items.map((item) => item.category)), "all"];

const Beauty = () => {
  const initial = {
    scale: 0.2,
    y: 40,
    opacity: 0,
  };

  const animation = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1,
        y: 0,
        opacity: 1,
      });
    }
  }, [inView, animation]);
  const transition = { delay: 0.3, duration: 0.6 };

  const [menuItems, setMenuItems] = useState(
    items.filter((item) => item.category === items[0].category)
  );
  const [activeCategory, setActiveCategory] = useState(items[0].category);
  const [categories, setCategories] = useState(allCategories);

  const filterItems = (category) => {
    setActiveCategory(category);
    if (category === "all") {
      setMenuItems(items);
      return;
    }
    const newItems = items.filter((item) => item.category === category);
    setMenuItems(newItems);
  };
  return (
    <main id="beauty" style={{ background: `url(${background})` }}>
      <section className="menu section">
        <div className="title">
          <h2>Beauty Service</h2>
          {/* <div className="underline"></div> */}
        </div>
        <CategoriesBeauty
          categories={categories}
          activeCategory={activeCategory}
          filterItems={filterItems}
        />
        <MenuBeauty items={menuItems} />
      </section>
      <ContentSec ref={ref} style={{ backgroundImage: `url(${background})` }}>
        <Container>
          <ContentRow>
            <ContentColumn>
              <TextWrapper>
                <ContentButton
                  initial={initial}
                  transition={{ ...transition, delay: 0.3 }}
                  animate={animation}
                >
                  <NavLinks
                    spy={true}
                    duration={500}
                    smooth={true}
                    exact="true"
                    offset={-80}
                    to="location"
                    style={{ color: lightYellow }}
                  >
                    BOOK NOW
                  </NavLinks>
                </ContentButton>
              </TextWrapper>
            </ContentColumn>
          </ContentRow>
        </Container>
      </ContentSec>
    </main>
  );
};

export default Beauty;
