import React, { useState } from "react";
import { Calendar } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  min-height: 500px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const DateInputBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  background: #f1f1f1;
  border-bottom: 4px solid #fed693;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  color: ${(props) => (props.hasValue ? "#333" : "#c1c1c1")};
  font-style: ${(props) => (props.hasValue ? "normal" : "italic")};
`;

const ModalButton = styled.button`
  background: #fed693;
  border: none;
  padding: 8px 16px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  &:hover {
    background: #f5c86a;
  }
`;

const parseDate = (date) => {
  if (!date) return null;
  const [year, month, day] = date.split("-");
  return { year: parseInt(year), month: parseInt(month), day: parseInt(day) };
};

const formatDate = (date) => {
  if (!date) return "";
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return `${date.year}-${date.month.toString().padStart(2, "0")}-${date.day
    .toString()
    .padStart(2, "0")}`;
};

const DateModal = ({ label, selectedDate, setSelectedDate }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <DateInputBox onClick={() => setIsOpen(true)} hasValue={!!selectedDate}>
        {selectedDate ? selectedDate : `Please select ${label}`}
      </DateInputBox>

      {isOpen && (
        <ModalOverlay onClick={() => setIsOpen(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <h2 style={{ color: "#000", marginBottom: "10px" }}>
              Select {label}
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Calendar
                value={parseDate(selectedDate)}
                onChange={(date) => setSelectedDate(formatDate(date))}
                minimumDate={{
                  year: new Date().getFullYear(),
                  month: new Date().getMonth() + 1,
                  day: new Date().getDate(),
                }}
                colorPrimary="#fed693"
                calendarClassName="custom-calendar" // custom styling (optional)
                shouldHighlightWeekends
              />
            </div>

            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <ModalButton onClick={() => setSelectedDate(null)}>
                Clear
              </ModalButton>
              <ModalButton
                onClick={() => setIsOpen(false)}
                style={{ marginLeft: "10px" }}
              >
                Done
              </ModalButton>
            </div>
          </ModalContent>
        </ModalOverlay>
      )}
    </div>
  );
};

export default DateModal;
