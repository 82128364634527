const menu = [
  {
    id: 1,
    title: "Upper Lip Wax ",
    category: "Waxing",
    price: 8,
    img: "./images/item-1.jpeg",
    desc: `10 mins`,
  },
  {
    id: 2,
    title: "Eyebrows Wax ",
    category: "Waxing",
    price: 10,
    img: "./images/item-2.jpeg",
    desc: `10 mis`,
  },
  {
    id: 3,
    title: "Bikini Wax from ",
    category: "Waxing",
    price: 40,
    img: "./images/item-3.jpeg",
    desc: `30 mins`,
  },
  {
    id: 4,
    title: "Brazilian Wax From ",
    category: "Waxing",
    price: 60,
    img: "./images/item-4.jpeg",
    desc: `30 mins`,
  },
  {
    id: 5,
    title: "Full Leg Waxing ",
    category: "Waxing",
    price: 65,
    img: "./images/item-5.jpeg",
    desc: `40 mins`,
  },
  {
    id: 6,
    title: "Half Leg Waxing ",
    category: "Waxing",
    price: 35,
    img: "./images/item-6.jpeg",
    desc: `20 mins`,
  },
  {
    id: 7,
    title: "Full Arm Waxing ",
    category: "Waxing",
    price: 55,
    img: "./images/item-6.jpeg",
    desc: `20 mins`,
  },
  {
    id: 8,
    title: "Half Arm Waxing ",
    category: "Waxing",
    price: 35,
    img: "./images/item-6.jpeg",
    desc: `15 mins`,
  },
  {
    id: 9,
    title: "Full Face Waxing ",
    category: "Waxing",
    price: 45,
    img: "./images/item-7.jpeg",
    desc: `25 mins`,
  },
  {
    id: 10,
    title: "Under Arm Waxing ",
    category: "Waxing",
    price: 15,
    img: "./images/item-8.jpeg",
    desc: `10 mins`,
  },
  {
    id: 11,
    title: "Chin Waxing",
    category: "Waxing",
    price: 10,
    img: "./images/item-9.jpeg",
    desc: `5 mins`,
  },
  {
    id: 12,
    title: "Eyebrow Tint",
    category: "Waxing",
    price: "15",
    img: "./images/item-9.jpeg",
    desc: `15 mins`,
  },
  //Brows & Lashes
  {
    id: 13,
    title: "Lash Lift & Tint",
    category: "Brows & Lashes",
    price: "80",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 14,
    title: "Mega Full",
    category: "Brows & Lashes",
    price: "110",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 30 mins`,
  },
  {
    id: 15,
    title: "Mega Infill ",
    category: "Brows & Lashes",
    price: "90",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 15 mins`,
  },
  {
    id: 16,
    title: "Light Mega Full ",
    category: "Brows & Lashes",
    price: "105",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 20 mins`,
  },
  {
    id: 17,
    title: "Light Mega Infill  ",
    category: "Brows & Lashes",
    price: "85",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 10 mins`,
  },
  {
    id: 18,
    title: "Volume Full ",
    category: "Brows & Lashes",
    price: "100",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 15 mins`,
  },
  {
    id: 19,
    title: "Volume Infill ",
    category: "Brows & Lashes",
    price: "80",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },

  {
    id: 20,
    title: "Light Volume Full ",
    category: "Brows & Lashes",
    price: "95",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 21,
    title: "Light Volume Infill ",
    category: "Brows & Lashes",
    price: "75",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 22,
    title: "Hybrid Full ",
    category: "Brows & Lashes",
    price: "90",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 23,
    title: "Hybrid Infill ",
    category: "Brows & Lashes",
    price: "70",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 24,
    title: "Light Hybrid full ",
    category: "Brows & Lashes",
    price: "85",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 25,
    title: "Light Hybrid Infill ",
    category: "Brows & Lashes",
    price: "65",
    img: "./images/item-9.jpeg",
    desc: `50 mins`,
  },
  {
    id: 26,
    title: "Classic Full ",
    category: "Brows & Lashes",
    price: "80",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 27,
    title: "Classic Infill ",
    category: "Brows & Lashes",
    price: "60",
    img: "./images/item-9.jpeg",
    desc: `45 mins`,
  },
  // Massage
  {
    id: 28,
    title: "Hot stone massage 90 mins",
    category: "Massage",
    price: "140",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 30 mins`,
  },
  {
    id: 29,
    title: "Hot stone massage 75 mins",
    category: "Massage",
    price: "120",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 15 mins`,
  },
  {
    id: 30,
    title: "Hot stone massage 60 mins",
    category: "Massage",
    price: "100",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 31,
    title: "Acupressure massage 90 mins",
    category: "Massage",
    price: "130",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 30 mins`,
  },
  {
    id: 32,
    title: "Acupressure massage 75 mins",
    category: "Massage",
    price: "110",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 15 mins`,
  },
  {
    id: 33,
    title: "Acupressure massage 60 mins",
    category: "Massage",
    price: "90",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 34,
    title: "Stretching massage 90 mins",
    category: "Massage",
    price: "130",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 30 mins`,
  },
  {
    id: 35,
    title: "Stretching massage 75 mins",
    category: "Massage",
    price: "110",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 15 mins`,
  },
  {
    id: 36,
    title: "Stretching massage 60 mins",
    category: "Massage",
    price: "90",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 37,
    title: "Couples massage 90 mins",
    category: "Massage",
    price: "255",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 30 mins`,
  },
  {
    id: 38,
    title: "Couples massage 60 mins",
    category: "Massage",
    price: "175",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 39,
    title: "Couples massage 30 mins",
    category: "Massage",
    price: "95",
    img: "./images/item-9.jpeg",
    desc: `30 mins`,
  },
  {
    id: 40,
    title: "Head/ neck & Shoulder/Back 90 mins",
    category: "Massage",
    price: "130",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 30 mins`,
  },
  {
    id: 41,
    title: "Head/ neck & Shoulder/Back 75 mins",
    category: "Massage",
    price: "110",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 15 mins`,
  },
  {
    id: 42,
    title: "Head/ neck & Shoulder/Back 60 mins",
    category: "Massage",
    price: "90",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 43,
    title: "Head/ neck & Shoulder/Back 30 mins",
    category: "Massage",
    price: "50",
    img: "./images/item-9.jpeg",
    desc: `30 mins`,
  },
  {
    id: 44,
    title: "Swedish Body Relaxation Massage for 90 minutes",
    category: "Massage",
    price: "130",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 30 mins`,
  },
  {
    id: 45,
    title: "Swedish Body Relaxation Massage for 75 minutes",
    category: "Massage",
    price: "110",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 15 mins`,
  },
  {
    id: 46,
    title: "Swedish Body Relaxation Massage for 60 minutes",
    category: "Massage",
    price: "90",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 45,
    title: "Swedish Body Relaxation Massage for 45 minutes",
    category: "Massage",
    price: "75",
    img: "./images/item-9.jpeg",
    desc: `45 mins`,
  },
  {
    id: 30,
    title: "Swedish Body Relaxation Massage for 30 minutes",
    category: "Massage",
    price: "50",
    img: "./images/item-9.jpeg",
    desc: `45 mins`,
  },
];
export default menu;
