import styled from "styled-components";
import { pink, orange, white, lightYellow, darkYellow } from "../../Colors";
import { motion } from "framer-motion";
import React, { forwardRef } from "react";

export const FormSection = styled.div`
  color: #fff;
  padding: 160px 0;
  /* background: ${({ inverse }) => (inverse ? "#101522" : "#fff")}; */
  background: #101522;

  @media screen and (max-width: 768px) {
    padding: 80px 0;
  }
`;

export const FormTitle = styled.h1`
  margin-bottom: ${({ mb }) => (mb ? mb : "10px")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "40px")};
  line-height: 1.1;
  font-weight: ${({ fontweight }) => (fontweight ? fontweight : "600")};
  color: ${({ co }) => (co ? co : lightYellow)};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "center")};
`;

export const FormSubTitle = styled.p`
  margin-bottom: ${({ mb }) => (mb ? mb : "5px")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1rem")};
  line-height: 1;
  font-weight: 300;
  color: #fff;
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "center")};
  @media screen and (max-width: 768px) {
    font-size: ${({ fontSizeMobile }) =>
      fontSizeMobile ? fontSizeMobile : "1rem"};
  }
`;

export const FormContainer = styled.div`
  display: flex;
`;
export const FormColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  padding: ${({ small }) => (small ? "0 50px" : "0 15px")};
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    margin-top: 0;
    max-width: 100% !important;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin-bottom: 5px;
  }

  img {
    @media screen and (max-width: 768px) {
      //   display: none;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: top;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    margin-top: 0;
    max-width: 100% !important;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }
`;

export const FormWrapper = styled.form`
  max-width: 540px;
  padding-top: 0;
  width: 100%;

  > button {
    width: 100%;
    height: 50px;
    margin-top: 1rem;
    background: linear-gradient(90deg, ${orange} 0%, ${pink} 100%);
    outline: none;
    border: none;
    color: #fff;
    font-size: 1rem;
  }
`;

export const FormMessage = styled(motion.div)`
  color: ${({ error }) => (error ? "red" : "green")};
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  margin-top: 1rem;
  max-width: 90%;
//   border: 1px solid ${({ error }) => (error ? "#fff" : "green")};
  text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5); 
  @media screen and (max-width: 768px) {
width: 100vw;
`;

export const FormInputRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 0.7rem;

  > p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    color: #f00e0e;
  }
`;
export const FormInput = styled.input`
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  border-bottom: 4px solid #fed693;
  font-size: 1.2rem;
  background: #f1f1f1;
  &::placeholder {
    color: ${(props) => (props.hasValue ? "#333" : "#c1c1c1")};
    font-style: ${(props) => (props.hasValue ? "normal" : "italic")};
  }
  @media screen and (max-width: 768px) {
    height: 35px;
  }
`;

export const FormTextArea = styled.textarea`
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 5px;
  height: 100px; /* Adjusted height */
  width: 100%;
  border-bottom: 4px solid #fed693;
  font-size: 1.2rem;
  background: #f1f1f1;
  resize: none;
  &::placeholder {
    color: ${(props) => (props.hasValue ? "#333" : "#c1c1c1")};
    font-style: ${(props) => (props.hasValue ? "normal" : "italic")};
  }
  @media screen and (max-width: 768px) {
    height: 70px;
  }
`;

export const FormLabel = styled.label`
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.3rem;
  color: #fff;
  text-transform: capitalise;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  /* padding-bottom: 40px; */

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
    > h1,
    p {
      text-align: center;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > img {
    width: 300px;
    margin-left: -3px;
  }
`;

export const ImgWrapper = styled(motion.div)`
  /* max-width: 600px; */
  display: flex;
  justify-content: ${({ imgStart }) => (imgStart ? "flex-start" : "flex-end")};
  max-height: 600px;
  border: 1px solid ${lightYellow};
  border-radius: 100%;
  @media screen and (max-width: 768px) {
    // display: none;
  }
`;

export const Img = styled(motion.img)`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  object-fit: cover;
  max-height: 300px;
  border-radius: 100%;
`;

export const AvatarSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center; /* Center items on smaller screens */
  }
`;
export const AvatarText = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the area without distortion */
`;

export const TopLine = styled(motion.div)`
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 1.3rem;
`;

export const Box = styled.span`
  background: #fefefe;
  padding: 10px;
  border-radius: 50px;
  color: black;

  > span {
    background: ${pink};
    padding: 5px 15px;
    border-radius: 50px;
    color: ${white};
    margin-right: 0.6rem;
  }
`;

export const Heading = styled(motion.h2)`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ inverse }) => (inverse ? "#f7f8fa" : "#1c2237")};
`;

export const Subtitle = styled(motion.p)`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ inverse }) => (inverse ? "#a9b3c1" : "#1c2237")};
`;

// selection modal
export const MultiSelectBox = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap into new lines */
  align-items: center;
  padding-left: 10px;
  outline: none;
  border-radius: 5px;
  min-height: 40px; /* Minimum height */
  max-height: 120px; /* Limit max height */
  overflow-y: auto; /* Enables scrolling if too many items */
  width: 100%;
  border-bottom: 4px solid #fed693;
  font-size: 1.2rem;
  background: #f1f1f1;
  cursor: pointer;
  color: ${(props) => (props.hasValue ? "#333" : "#c1c1c1")};
  font-style: ${(props) => (props.hasValue ? "normal" : "italic")};

  /* Smooth transition for height changes */
  transition: all 0.2s ease-in-out;
`;

// Modal Styles
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  min-height: 600px;
  max-width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

export const OptionRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  &:hover {
    background: #f9f9f9;
  }
`;

export const ModalButton = styled.button`
  background: ${lightYellow};
  color: ${darkYellow};
  border: none;
  padding: 8px 16px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  &:hover {
    background: ${darkYellow};
    color: ${lightYellow};
  }
`;

export const CategoryTitle = styled.h3`
  margin-top: 10px;
  font-size: 1.1rem;
  color: #333;
  border-bottom: 1px solid ${lightYellow};
  padding-bottom: 5px;
`;

export const CategoryTabs = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  border-bottom: 1px solid ${lightYellow};
  margin-bottom: 20px;
  padding-bottom: 5px;
  scrollbar-width: thin;
  scrollbar-color: ${lightYellow} transparent;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #fed693;
    border-radius: 10px;
    color: ${darkYellow};
  }
`;

// Each Category Tab (Horizontal)
export const CategoryTab = styled.div`
  flex: 0 0 auto; /* Prevents tabs from shrinking */
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  color: #333;
  background: ${(props) => (props.active ? "#fed693" : "#f1f1f1")};
  border-radius: 5px;
  margin-right: 10px;
  &:hover {
    background: #f5c86a;
  }
`;

// Style for Item List (Vertical)
export const ItemList = styled.div`
  display: flex;
  flex-direction: column;
`;

// Style for Each Item Row (Vertical)
export const ItemRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  &:hover {
    background: #f9f9f9;
  }
`;
