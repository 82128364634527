import styled from "styled-components";
import { Link } from "react-router-dom";
import { Column, Row } from "../../globalStyles";

export const FooterRow = styled(Row)`
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px 00px;
  color: #fff;
  margin: auto;
  max-width: 1280px;
  @media screen and (max-width: 720px) {
    justify-content: center;
    padding: 20px 0; // Adjust padding for better spacing on small screens
    width: 90vw;
  }
`;

export const FooterRowAdd = styled(Row)`
  display: flex;
  flex-direction: column;
  align-item: center;
  justify-content: center;
  padding: 0px 0px;
  color: #fff;
  margin: auto;
  max-width: 100%;
  @media screen and (max-width: 720px) {
    justify-content: center;
    padding: 0px; // Add padding to maintain spacing on mobile
  }
`;

export const FooterRowAdd2 = styled(Row)`
  flex-wrap: wrap;
  justify-content: center;
  padding: 25px 0px;
  color: #fff;
  margin: auto;
  //   max-height: 100px;
  max-width: 10rem;
  @media screen and (max-width: 720px) {
    justify-content: center;
    padding: 20px;
    max-width: 100%; // Ensure content takes full width on small screens
  }
`;

export const FooterColumn = styled(Column)`
  margin: 0.5rem 1rem;
  padding: 1rem;
  max-width: 16rem;

  box-shadow: 0 0 0px #fed693, 0 0 0px #fed693, 0 0 00px #fed693,
    0 0 10px #fed693;
  // -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  @media screen and (max-width: 720px) {
    max-width: 25rem;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
  }
`;

export const SocialIcon = styled.img`
  margin: 0 auto;
  width: 40px;
`;

export const FooterSocialIcon = styled.a`
  color: #fff;
  font-size: 24px;
  margin: 1rem auto;
`;

export const FooterForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    width: 80%;
  }
`;

export const FooterInput = styled.input`
  padding: 10px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 16px;
  border: 1px solid #fff;

  &::placeholder {
    color: #242424;
  }

  @media screen and (max-width: 820px) {
    width: 100%;
    margin: 0 0 16px 0;
  }
`;

export const FooterLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px auto 0;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
`;
