import React, { useEffect, useRef, useState } from "react";
import {
  FormColumn,
  Img,
  ImgWrapper,
  FormWrapper,
  FormInput,
  FormSection,
  FormRow,
  FormTitle,
  FormLabel,
  FormInputRow,
  FormMessage,
  FormTextArea,
  FormSubTitle,
  AvatarWrapper,
  AvatarImage,
  AvatarSection,
  AvatarText,
} from "./FormStyles";
import styled from "styled-components";

import { Container, Button, Row, TextWrapper } from "../../globalStyles";
import { white } from "../../Colors";
import validateForm from "./validateForm";
import { LocationData } from "../../data/footerData";
import { Helmet } from "react-helmet";
import Avatar from "./AvatarImage";
import toast, { Toaster } from "react-hot-toast"; // Import Toaster from react-hot-toast
import itemNail from "../NailsMenu/dataNails";
import itemBeauty from "../BeautyMenu/dataBeauty";
import MultiSelectModal from "./MultiSelectModal";
import DateModal from "./DateModal";
import emailjs from "@emailjs/browser";
import TimeModal from "./TimeModal";

const FormBooking = (props) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []); // Runs only once on mount

  const locationTag = props.path.tag;
  // console.log(locationTag);
  const store = LocationData.find((location) =>
    location.fresha.includes(locationTag)
  );

  console.log({ store });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [mobile, setMobile] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [selectedNailServices, setSelectedNailServices] = useState([]);
  const [selectedBeautyServices, setSelectedBeautyServices] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null); // Initialize as null
  const [selectedTime, setSeletedTime] = useState("");

  const form = useRef(null); // Emailjs

  const handleSubmit = (e) => {
    e.preventDefault();
    const resultError = validateForm({
      name,
      email,
      mobile,
      message,
      selectedBeautyServices,
      selectedNailServices,
      selectedDate,
      selectedTime,
    });

    if (resultError !== null) {
      setError(resultError);
      toast.error(resultError);
      return;
    }

    if (!store) {
      toast.error("Location Data not found.");
      return;
    }

    const templateParams = {
      from_name: name,
      from_email: email,
      message,
      mobile,
      selectedBeautyServices: selectedBeautyServices.join(", "),
      selectedNailServices: selectedNailServices.join(", "),
      // services: [...selectedNailServices, ...selectedBeautyServices].join(", "),
      appointment_date: selectedDate ? selectedDate.toString() : "Not set",
    };

    emailjs
      .sendForm(
        store.YOUR_SERVICE_ID,
        store.YOUR_TEMPLATE_ID,
        form.current, // ✅ Corrected this
        // templateParams,
        {
          publicKey: store.YOUR_PUBLIC_KEY,
        }
      )
      .then(
        (result) => {
          console.log("SUCCESS! -->", result.text);
          setSuccess("Application was submitted!");
          toast.success(
            "Your booking has been submitted. Our staff will get in touch with you as soon as possible.",
            { duration: 4000 } // Correct placement inside an object
          );

          setName("");
          setEmail("");
          setMobile("");
          setMessage("");
          setSelectedDate("");
          setSelectedNailServices([]);
          setSelectedBeautyServices([]);
          setSelectedDate("");
          setError(null);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      color: white,
      boxShadow: "0px 0px 8px white",
      transition: {
        duration: 0.3,
        yoyo: Infinity,
      },
    },
  };

  const handleMultiSelectChange = (e, setter) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    setter(selectedOptions);
  };

  const formData = [
    {
      label: "Name (*)",
      id: "input1",
      name: "user_name",
      value: name,
      onChange: (e) => setName(e.target.value),
      type: "text",
    },
    {
      label: "Email (*)",
      id: "input2",
      name: "to_name",
      value: email,
      onChange: (e) => setEmail(e.target.value),
      type: "email",
    },
    {
      label: "Mobile (*)",
      id: "input3",
      name: "from_name",
      value: mobile,
      onChange: (e) => setMobile(e.target.value),
      type: "mobile",
    },
    {
      label: "Nail Service (*)",
      name: "user_nailservices",
      value: selectedNailServices,
      onChange: (e) => handleMultiSelectChange(e, setSelectedNailServices),
      type: "nailservice",
      options: itemNail.map((item) => item.title),
    },

    {
      label: "Beauty Service (*)",
      name: "user_beautyservices",
      value: selectedBeautyServices,
      onChange: (e) => handleMultiSelectChange(e, setSelectedBeautyServices),
      type: "beautyservice",
      options: itemBeauty.map((item) => item.title), // Extracting titles for selection
    },
    {
      label: "Date (*)",
      name: "user_date",
      value: selectedDate,
      onChange: (date) => setSelectedDate(date),
      type: "date",
    },
    {
      label: "Preferred Time (*)",
      name: "user_time",
      value: selectedTime,
      onChange: (time) => setSeletedTime(time),
      type: "time",
    },
    {
      label: "Message (*)",
      name: "user_message",
      value: message,
      onChange: (e) => setMessage(e.target.value),
      type: "textarea",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Booking | {store.title}</title>
        <meta name="description" content={store.town} />
        <meta property="og:title" content={store.title} />
        <meta property="og:description" content={store.email} />
        <meta property="og:image" content={store.img} />
      </Helmet>

      <FormSection>
        <Container>
          <FormRow small>
            <FormColumn small>
              <p style={{ textTransform: "uppercase" }}>
                Stay in touch with us
              </p>
              <FormTitle textAlign="start" fontSize="36px" mb="5px">
                {" "}
                Booking Now
              </FormTitle>
              <div
                style={{ border: "0.5px solid #fed693", maxWidth: "100px" }}
              ></div>
            </FormColumn>
            <FormColumn></FormColumn>
          </FormRow>
          <FormRow>
            <FormColumn>
              <AvatarSection>
                <div>
                  <Avatar
                    imgStart={true} // Set to false to align the image to the right
                    imgSrc={store.img}
                    altText="Avatar"
                  />
                </div>
                <AvatarText>
                  <FormTitle
                    textAlign="start"
                    fontSize="20px"
                    co="#fff"
                    fontweight="300"
                    mb="0.5rem"
                  >
                    {store.town}
                  </FormTitle>
                  <FormTitle textAlign="start" fontSize="24px">
                    {store.title}
                  </FormTitle>
                  <FormSubTitle textAlign="start" fontSize="1.2rem">
                    💈 {store.links[0]},&nbsp;{store.links[1]}
                  </FormSubTitle>
                  <FormSubTitle textAlign="start" fontSize="1.2rem">
                    📱 {store.tel_no}
                  </FormSubTitle>
                  <FormSubTitle textAlign="start" fontSize="1.2rem">
                    📩 {store.email}
                  </FormSubTitle>
                </AvatarText>
              </AvatarSection>
              <TextWrapper
                weight="300"
                size="1rem"
                mb="1rem"
                mt="1rem"
                color="#fed693"
                style={{ textAlign: "start", fontStyle: "italic" }}
              >
                Please field the below form and submit (*)
              </TextWrapper>
            </FormColumn>
            <FormColumn>
              <FormWrapper onSubmit={handleSubmit} ref={form}>
                {formData.map((el, index) => (
                  <FormInputRow key={index}>
                    <FormLabel>{el.label}</FormLabel>
                    {el.type === "textarea" ? (
                      <FormTextArea
                        value={el.value}
                        name={el.name}
                        onChange={el.onChange}
                        placeholder={`Please write your ${el.label.toLowerCase()}`}
                      />
                    ) : el.type === "nailservice" ? (
                      <>
                        <MultiSelectModal
                          name={el.name}
                          label={el.label}
                          options={itemNail}
                          selectedValues={selectedNailServices}
                          setSelectedValues={setSelectedNailServices}
                        />
                        <input
                          id="myInput1"
                          type="hidden"
                          name="reply_to"
                          value={selectedNailServices.join(" | ")}
                        />
                        <input
                          id="myInputStore"
                          type="hidden"
                          name="reply_to4"
                          value={store.title + ", " + store.town}
                        />
                      </>
                    ) : el.type === "beautyservice" ? (
                      <>
                        <MultiSelectModal
                          name={el.name}
                          label={el.label}
                          options={itemBeauty}
                          selectedValues={selectedBeautyServices}
                          setSelectedValues={setSelectedBeautyServices}
                        />

                        <input
                          id="myInput2"
                          type="hidden"
                          name="reply_to2"
                          value={selectedBeautyServices.join(" | ")}
                        />
                      </>
                    ) : el.type === "date" ? (
                      <>
                        <DateModal
                          name={el.name}
                          label={el.label}
                          selectedDate={selectedDate}
                          setSelectedDate={setSelectedDate}
                        />

                        <input
                          id="myInput3"
                          type="hidden"
                          name="reply_to3"
                          value={selectedDate}
                        />
                      </>
                    ) : el.type === "time" ? (
                      <>
                        <TimeModal
                          store={store.town}
                          name="myInput4"
                          label={el.label}
                          selectedTime={selectedTime}
                          setSelectedTime={setSeletedTime}
                        />
                        <input
                          id="myInput5"
                          type="hidden"
                          name="reply_to5"
                          value={selectedTime}
                        />
                      </>
                    ) : (
                      <>
                        <FormInput
                          id={el.id}
                          name={el.name}
                          type={el.type}
                          value={el.value}
                          onChange={el.onChange}
                          placeholder={`Please write your ${el.label.toLowerCase()}`}
                        />
                      </>
                    )}
                  </FormInputRow>
                ))}

                <Button
                  variants={buttonVariants}
                  whileHover="hover"
                  type="submit"
                >
                  Booking
                </Button>
              </FormWrapper>
              {error && (
                <>
                  <FormMessage
                    variants={messageVariants}
                    initial="hidden"
                    animate="animate"
                    error
                  >
                    {error}
                  </FormMessage>
                </>
              )}
              {success && (
                <FormMessage
                  variants={messageVariants}
                  initial="hidden"
                  animate="animate"
                >
                  {success}
                </FormMessage>
              )}
            </FormColumn>
          </FormRow>
        </Container>
      </FormSection>
    </>
  );
};

export default FormBooking;
