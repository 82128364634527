import React, { useState } from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  min-height: 250px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const TimeInputBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  width: 100%;
  padding-left: 10px;
  background: #f1f1f1;
  border-bottom: 4px solid #fed693;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  color: ${(props) => (props.hasValue ? "#333" : "#c1c1c1")};
  font-style: ${(props) => (props.hasValue ? "normal" : "italic")};
`;

const ModalButton = styled.button`
  background: #fed693;
  border: none;
  padding: 8px 16px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  &:hover {
    background: #f5c86a;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
`;

const generateTimeOptions = (store) => {
  const times = [];
  let startHour = 9;
  let startMinute = 0;
  let endHour = 17.5; // Ensure max booking time is 17:00

  const now = new Date();
  const currentDay = now.getDay(); // 0 = Sunday

  console.log({ currentDay });

  if (currentDay === 0) {
    // Sunday
    if (store === "KAIKOURA") {
      startHour = 12;
      startMinute = 0;
      endHour = 15.5;
    } else if (store === "PICTON") {
      startHour = 11;
      startMinute = 45;
      endHour = 15.5;
    }
  }

  for (let hour = startHour; hour <= endHour; hour++) {
    if (hour === startHour && startMinute === 45) {
      times.push(`${hour}:45 AM`);
    } else {
      times.push(`${hour}:00 ${hour < 12 ? "AM" : "PM"}`);
      if (hour < endHour) {
        times.push(`${hour}:30 ${hour < 12 ? "AM" : "PM"}`);
      }
    }
  }
  return times;
};

const TimeModal = ({ label, selectedTime, setSelectedTime, store }) => {
  const [isOpen, setIsOpen] = useState(false);
  const timeOptions = generateTimeOptions(store);
  console.log({ store });

  return (
    <div>
      <TimeInputBox onClick={() => setIsOpen(true)} hasValue={!!selectedTime}>
        {selectedTime ? selectedTime : `Please select ${label}`}
      </TimeInputBox>

      {isOpen && (
        <ModalOverlay onClick={() => setIsOpen(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <h2 style={{ color: "#000", marginBottom: "10px" }}>
              Select {label}
            </h2>

            <Select
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
            >
              <option value="" disabled>
                Select a time
              </option>
              {timeOptions.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </Select>

            <div style={{ textAlign: "right", marginTop: "10px" }}>
              <ModalButton onClick={() => setSelectedTime(null)}>
                Clear
              </ModalButton>
              <ModalButton
                onClick={() => setIsOpen(false)}
                style={{ marginLeft: "10px" }}
              >
                Done
              </ModalButton>
            </div>
          </ModalContent>
        </ModalOverlay>
      )}
    </div>
  );
};

export default TimeModal;
