const menu = [
  {
    id: 1,
    title: "Cut shape and paint toe nails with gel polish",
    category: "Nails",
    price: 50,
    img: "./images/item-1.jpeg",
    desc: "30 mins",
  },
  {
    id: 2,
    title: "Cut shape and paint toe nails with regular polish",
    category: "Nails",
    price: 35,
    img: "./images/item-2.jpeg",
    desc: `30 mins`,
  },
  {
    id: 3,
    title: "Spa pedicure with gel polish",
    category: "Nails",
    price: 65,
    img: "./images/item-2.jpeg",
    desc: `1 hr`,
  },
  {
    id: 4,
    title: "Spa pedicure with regular polish ",
    category: "Nails",
    price: 50,
    img: "./images/item-4.jpeg",
    desc: `1 hr`,
  },
  {
    id: 5,
    title: "Remove dipping / acrylic/ builder gel ",
    category: "Nails",
    price: 25,
    img: "./images/item-5.jpeg",
    desc: `30 mins`,
  },
  {
    id: 6,
    title: "Remove gel",
    category: "Nails",
    price: 20,
    img: "./images/item-6.jpeg",
    desc: `30 mins`,
  },
  {
    id: 7,
    title: "Cut shape toe nail only ",
    category: "Nails",
    price: 20,
    img: "./images/item-7.jpeg",
    desc: `30 mins`,
  },
  {
    id: 8,
    title: "Cut shape finger nail only",
    category: "Nails",
    price: 15,
    img: "./images/item-7.jpeg",
    desc: `20 mins`,
  },
  {
    id: 9,
    title: "Cut shape and paint finger nail with regular polish",
    category: "Nails",
    price: 25,
    img: "./images/item-9.jpeg",
    desc: `30 mins`,
  },
  {
    id: 10,
    title: "Cut shape and paint finger nail with gel polish ",
    category: "Nails",
    price: "40",
    img: "./images/item-9.jpeg",
    desc: `30 mins`,
  },
  {
    id: 11,
    title: "Heel treatment ",
    category: "Nails",
    price: "50",
    img: "./images/item-9.jpeg",
    desc: `45 mins`,
  },
  {
    id: 12,
    title: "Acrylic infill from",
    category: "Nails",
    price: "70",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 13,
    title: "Acrylic from ",
    category: "Nails",
    price: "85",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 14,
    title: "Dipping Powder with Design from ",
    category: "Nails",
    price: "65",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 15,
    title: "Dipping Powder with Extensions from ",
    category: "Nails",
    price: "75",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 16,
    title: "Dipping Powder on natural nails ",
    category: "Nails",
    price: "65",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  {
    id: 17,
    title: "Builder Gel with Design from ",
    category: "Nails",
    price: "65",
    img: "./images/item-9.jpeg",
    desc: `1 hr 20 mins`,
  },
  {
    id: 18,
    title: "Manicure with gel polish ",
    category: "Nails",
    price: "50",
    img: "./images/item-9.jpeg",
    desc: `45 mins`,
  },
  {
    id: 19,
    title: "Nail Art From ",
    category: "Nails",
    price: "5",
    img: "./images/item-9.jpeg",
    desc: `25 mins`,
  },
  {
    id: 20,
    title: "Manicure with regular polish ",
    category: "Nails",
    price: "35",
    img: "./images/item-9.jpeg",
    desc: `35 mins`,
  },
  {
    id: 21,
    title: "Pedicure with regular polish",
    category: "Nails",
    price: "50",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
  // combos
  {
    id: 22,
    title: "Combo manicure and pedicure with gel polish",
    category: "Combos",
    price: "110",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 40 mins`,
  },
  {
    id: 23,
    title: "Combo manicure and pedicure with regular polish",
    category: "Combos",
    price: "80",
    img: "./images/item-9.jpeg",
    desc: `1 hr, 40 mins`,
  },
  {
    id: 24,
    title: "Eye trio (Brow shape+tint, lash tint)",
    category: "Combos",
    price: "45",
    img: "./images/item-9.jpeg",
    desc: `30 mins`,
  },
  {
    id: 25,
    title: "Brow Duo (wax + Hybrid tint)",
    category: "Combos",
    price: "35",
    img: "./images/item-9.jpeg",
    desc: `25 mins`,
  },
  {
    id: 26,
    title: "Brow duo (Wax+tint) ",
    category: "Combos",
    price: "25",
    img: "./images/item-9.jpeg",
    desc: `20 mins`,
  },
  {
    id: 27,
    title: "Henna Brows",
    category: "Combos",
    price: "60",
    img: "./images/item-9.jpeg",
    desc: `50 mins`,
  },
  {
    id: 28,
    title: "Lip & Chin & Eyebrow Waxing ",
    category: "Combos",
    price: "25",
    img: "./images/item-9.jpeg",
    desc: `20 mins`,
  },
  {
    id: 29,
    title: "Brow Lamination & Tint ",
    category: "Combos",
    price: "70",
    img: "./images/item-9.jpeg",
    desc: `1 hr`,
  },
];
export default menu;
