// export const orange = '#ebc214';
export const orange = "#fed693";

// export const pink = '#d61eed';
export const pink = "#ca7100";

export const white = "#f7f8fa";

export const lightYellow = "#fed693";
export const darkYellow = "#ca7100";
