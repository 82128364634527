import { lightGreen } from "@mui/material/colors";
import React from "react";
import styled from "styled-components";
import { lightYellow } from "../../Colors";

const AvatarWrapper = styled.div`
  display: flex;
  justify-content: ${({ imgStart }) => (imgStart ? "flex-start" : "flex-end")};
  width: auto; /* Set desired width */
  height: 300px;
  border: 1px solid ${lightYellow}; /* Replace with your lightYellow variable */
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    display: block;
    width: 88vw;
    height: 150px; /* Fixed height on mobile */
    padding: 0px;
    overflow: hidden;
  }
`;

const AvatarImage = styled.img`
  width: 505px;
  height: auto;
  object-fit: cover; /* Ensures the image covers the area without distortion */
  transition: transform 0.3s ease; /* Smooth transition for the hover effect */
  &:hover {
    transform: scale(1.1); /* Scale the image on hover */
  }

  @media screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin: 0px;
  }
`;

const Avatar = ({ imgStart, imgSrc, altText }) => (
  <AvatarWrapper imgStart={imgStart}>
    <AvatarImage src={imgSrc} alt={altText} />
  </AvatarWrapper>
);

export default Avatar;
