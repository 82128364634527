import React from "react";
import Form from "../../components/Form/Form";
import FormBooking from "../../components/Form/FormBooking";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const Booking = () => {
  const path = useParams();
  console.log({ path });
  return (
    <>
      <FormBooking path={path} />
    </>
  );
};

export default Booking;
