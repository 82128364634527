import React, { useState, useRef, useEffect } from "react";
import {
  CategoryTab,
  CategoryTabs,
  CategoryTitle,
  ItemList,
  ItemRow,
  ModalButton,
  ModalContent,
  ModalOverlay,
  MultiSelectBox,
  OptionRow,
} from "./FormStyles";

const MultiSelectModal = ({
  name,
  label,
  options,
  selectedValues,
  setSelectedValues,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(options[0].category);
  const categoryRefs = useRef({});
  const tabListRef = useRef(null);

  console.log({ options });

  // Group options by category
  const groupedOptions = options.reduce((acc, item) => {
    if (!acc[item.category]) acc[item.category] = [];
    acc[item.category].push(item);
    return acc;
  }, {});

  // Toggle selection
  const toggleSelection = (value, category) => {
    setSelectedValues((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );

    setActiveCategory(category);
  };

  // Scroll category list horizontally to keep active category in view
  useEffect(() => {
    if (tabListRef.current) {
      const activeTab = tabListRef.current.querySelector(
        `[data-category="${activeCategory}"]`
      );
      if (activeTab) {
        activeTab.scrollIntoView({ behavior: "smooth", inline: "center" });
      }
    }
  }, [activeCategory]);

  // Handle category click - smooth vertical scroll
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    categoryRefs.current[category]?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  // Auto-detect scrolling category using IntersectionObserver
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveCategory(entry.target.getAttribute("data-category"));
          }
        });
      },
      { threshold: 0.6 }
    );

    Object.keys(groupedOptions).forEach((category) => {
      if (categoryRefs.current[category]) {
        observer.observe(categoryRefs.current[category]);
      }
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div>
      <MultiSelectBox
        onClick={() => setIsOpen(true)}
        hasValue={selectedValues.length > 0}
      >
        {selectedValues.length > 0
          ? selectedValues.join(" | ")
          : `Please select ${label}`}
      </MultiSelectBox>

      {isOpen && (
        <ModalOverlay onClick={() => setIsOpen(false)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <h2 style={{ color: "#000", marginBottom: "10px" }}>{label}</h2>

            {/* Category Tabs - Scrollable Horizontally */}
            <CategoryTabs
              ref={tabListRef}
              style={{
                overflowX: "auto",
                whiteSpace: "nowrap",
                display: "flex",
              }}
            >
              {Object.keys(groupedOptions).map((category) => (
                <CategoryTab
                  key={category}
                  data-category={category}
                  active={category === activeCategory}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </CategoryTab>
              ))}
            </CategoryTabs>

            {/* Items List - Scrollable Vertically */}
            <ItemList style={{ maxHeight: "400px", overflowY: "auto" }}>
              {Object.keys(groupedOptions).map((category) => (
                <div
                  key={category}
                  data-category={category}
                  ref={(el) => (categoryRefs.current[category] = el)}
                >
                  <h3 style={{ color: "#000", margin: "10px 0" }}>
                    {category}
                  </h3>
                  {groupedOptions[category].map((item) => (
                    <ItemRow
                      key={item.id}
                      onClick={() => toggleSelection(item.title, item.category)}
                    >
                      <input
                        type="checkbox"
                        checked={selectedValues.includes(item.title)}
                        readOnly
                      />
                      <span style={{ marginLeft: "10px", color: "#000" }}>
                        {item.title} - ${item.price}
                      </span>
                    </ItemRow>
                  ))}
                </div>
              ))}
            </ItemList>

            {/* Action Buttons */}
            <div style={{ textAlign: "right" }}>
              <ModalButton onClick={() => setSelectedValues([])}>
                Clear
              </ModalButton>
              <ModalButton
                onClick={() => setIsOpen(false)}
                style={{ marginLeft: "10px" }}
              >
                Done
              </ModalButton>
            </div>
          </ModalContent>
        </ModalOverlay>
      )}
    </div>
  );
};

// const MultiSelectModal2 = ({
//   name,
//   label,
//   options,
//   selectedValues,
//   setSelectedValues,
// }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [activeCategory, setActiveCategory] = useState(options[0].category);
//   const categoryRefs = useRef({});
//   const tabListRef = useRef(null);

//   // Group options by category
//   const groupedOptions = options.reduce((acc, item) => {
//     if (!acc[item.category]) acc[item.category] = [];
//     acc[item.category].push(item);
//     return acc;
//   }, {});

//   // Scroll category tabs when activeCategory changes
//   useEffect(() => {
//     if (tabListRef.current) {
//       const activeTab = tabListRef.current.querySelector(
//         `[data-category="${activeCategory}"]`
//       );
//       if (activeTab) {
//         activeTab.scrollIntoView({ behavior: "smooth", inline: "center" });
//       }
//     }
//   }, [activeCategory]);

//   // Observe which category is in view & update activeCategory
//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             setActiveCategory(entry.target.getAttribute("data-category"));
//           }
//         });
//       },
//       { threshold: 0.6 } // Adjust visibility threshold
//     );

//     Object.keys(groupedOptions).forEach((category) => {
//       if (categoryRefs.current[category]) {
//         observer.observe(categoryRefs.current[category]);
//       }
//     });

//     return () => observer.disconnect();
//   }, []);

//   return (
//     <div>
//       <MultiSelectBox
//         onClick={() => setIsOpen(true)}
//         hasValue={selectedValues.length > 0}
//       >
//         {selectedValues.length > 0
//           ? selectedValues.join(" | ")
//           : `Please select ${label}`}
//       </MultiSelectBox>

//       {isOpen && (
//         <ModalOverlay onClick={() => setIsOpen(false)}>
//           <ModalContent onClick={(e) => e.stopPropagation()}>
//             <h2 style={{ color: "#000", marginBottom: "10px" }}>{label}</h2>

//             {/* Category Tabs */}
//             <CategoryTabs
//               ref={tabListRef}
//               style={{
//                 overflowX: "auto",
//                 display: "flex",
//                 whiteSpace: "nowrap",
//               }}
//             >
//               {Object.keys(groupedOptions).map((category) => (
//                 <CategoryTab
//                   key={category}
//                   data-category={category}
//                   active={category === activeCategory}
//                 >
//                   {category}
//                 </CategoryTab>
//               ))}
//             </CategoryTabs>

//             {/* Items List - Detect Scroll to Change Tabs */}
//             <ItemList style={{ maxHeight: "400px", overflowY: "auto" }}>
//               {Object.keys(groupedOptions).map((category) => (
//                 <div
//                   key={category}
//                   data-category={category}
//                   ref={(el) => (categoryRefs.current[category] = el)}
//                 >
//                   <h3 style={{ color: "#000", margin: "10px 0" }}>
//                     {category}
//                   </h3>
//                   {groupedOptions[category].map((item) => (
//                     <ItemRow key={item.id}>
//                       <span style={{ marginLeft: "10px", color: "#000" }}>
//                         {item.title} - ${item.price}
//                       </span>
//                     </ItemRow>
//                   ))}
//                 </div>
//               ))}
//             </ItemList>

//             {/* Action Buttons */}
//             <div style={{ textAlign: "right" }}>
//               <ModalButton onClick={() => setSelectedValues([])}>
//                 Clear
//               </ModalButton>
//               <ModalButton
//                 onClick={() => setIsOpen(false)}
//                 style={{ marginLeft: "10px" }}
//               >
//                 Done
//               </ModalButton>
//             </div>
//           </ModalContent>
//         </ModalOverlay>
//       )}
//     </div>
//   );
// };

export default MultiSelectModal;
