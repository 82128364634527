import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import Signup from "./pages/SignupPage/Signup";
import GlobalStyle from "./globalStyles";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Booking from "./pages/Booking/Booking";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Toaster position="top-center" duration="4000" />
      <Navbar></Navbar>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/sign-up" exact component={Signup} />
        <Route path="/booking/:tag" component={Booking} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
