import React, { useEffect, useState } from "react";
import { Container, Button, Section } from "../../globalStyles";
import {
  FeatureText,
  FeatureSection,
  FeatureTitle,
  FeatureWrapper,
  FeatureColumn,
  FeatureImageWrapper,
  FeatureName,
  FeatureImage,
  FeatureTextWrapper,
  FeatureMainText,
  FeatureIcon,
} from "./FeaturesStyles";
import { featuresData } from "../../data/FeaturesData";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  ContentSec,
  ContentRow,
  TextWrapper,
  TopLine,
  ContentHeading,
  ContentButton,
  Subtitle,
  ImgWrapper,
  Img,
  ContentColumn,
} from "./ContentStyles";
import Modal from "../Modal/Modal";
import { fresha_url } from "../../fresha";
import { NavLinks } from "../Navbar/NavbarStyles";

const Features = (props) => {
  const initial = {
    scale: 0.2,
    y: 40,
    opacity: 0,
  };

  const animation = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1,
        y: 0,
        opacity: 1,
      });
    }
  }, [inView, animation]);

  ////content section

  const {
    primary,
    topLine,
    headline,
    description,
    buttonLabel,
    img,
    alt,
    start,
    bottomImg,
    linkTo,
    inverse,
    reverse,
    bigImage,
    id,
  } = props;

  const transition = { delay: 0, duration: 0.3 };
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      });

      return;
    }
  }, [inView, animation]);

  return (
    <FeatureSection id="about">
      <ContentSec inverse={inverse} ref={ref}>
        <Container>
          <ContentRow reverse={reverse}>
            <ContentColumn>
              <TextWrapper>
                <ContentHeading>{headline}</ContentHeading>
                <Subtitle inverse={inverse}>{description}</Subtitle>
                <ContentButton
                  initial={initial}
                  transition={{ ...transition, delay: 0.2 }}
                  animate={animation}
                  inverse={inverse}
                  primary={primary}
                >
                  <NavLinks
                    spy={true}
                    duration={500}
                    smooth={true}
                    exact="true"
                    offset={-80}
                    to="location"
                  >
                    BOOK NOW
                  </NavLinks>
                </ContentButton>
              </TextWrapper>
            </ContentColumn>
            <ContentColumn
              initial={animation}
              transition={transition}
              animate={animation}
            >
              <ImgWrapper>
                <Img
                  src={img}
                  alt={alt}
                  initial={{ rotate: 3 }}
                  whileHover={{ rotate: 0, scale: 1.02 }}
                  transition={{ duration: 1 }}
                />
              </ImgWrapper>
            </ContentColumn>
          </ContentRow>
        </Container>
      </ContentSec>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <ContentHeading inverse={inverse}>{headline}</ContentHeading>
        <ImgWrapper>
          <Img src={img} alt={alt} />
        </ImgWrapper>
        <Subtitle inverse={inverse}>{description}</Subtitle>
        <ContentButton inverse={inverse} primary={primary}>
          Buy Tickets
        </ContentButton>
      </Modal>

      <Container ref={ref}>
        <FeatureTextWrapper
          initial={initial}
          animate={animation}
          transition={{ delay: 0.5, duration: 1 }}
        >
          <FeatureTitle>{featuresData.title}</FeatureTitle>
          <FeatureMainText>{featuresData.text}</FeatureMainText>
        </FeatureTextWrapper>
        <FeatureWrapper>
          {featuresData.content.map((el, index) => (
            <FeatureColumn
              initial={initial}
              animate={animation}
              transition={{ delay: 0.5, duration: 0.7 + index * 0.2 }}
              key={index}
            >
              <FeatureImageWrapper>
                <FeatureIcon>{el.icon}</FeatureIcon>
              </FeatureImageWrapper>
              <FeatureName>{el.name}</FeatureName>
              <FeatureText>{el.description}</FeatureText>
            </FeatureColumn>
          ))}
        </FeatureWrapper>
      </Container>
    </FeatureSection>
  );
};

export default Features;
