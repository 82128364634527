import React, { useEffect, useState } from "react";
import {
  Button,
  Column,
  Container,
  Row,
  Section,
  TextWrapper,
} from "../../globalStyles";
import {
  FooterForm,
  FooterInput,
  FooterLogo,
  SocialIcon,
  WebsiteRights,
  FooterColumn,
  FooterSocialIcon,
  FooterRow,
  FooterLine,
  FooterRowAdd,
  FooterRowAdd2,
} from "./LocationStyles";
import { LocationData } from "../../data/footerData";
import background from "./black-paper-3.jpg";
import {
  ContentButton,
  ContentHeading,
  Subtitle,
  ImgWrapper,
  Img,
  ContentColumn,
} from "./ContentStyles";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled, { keyframes, css } from "styled-components";
import { FaCheckCircle, FaClock } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { FaBellSlash } from "react-icons/fa";
import { ImgLocation } from "../Form/ContentStyles";

const vibrate = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-15deg); }  // Increased rotation
  20% { transform: rotate(15deg); }   // Increased rotation
  30% { transform: rotate(-15deg); }  // Increased rotation
  40% { transform: rotate(15deg); }   // Increased rotation
  50% { transform: rotate(0deg); }
`;

// Flashing animation when open, from white to golden color
const flash = keyframes`
  0% { color: transparent; }
  50% { color: green; }  // Flash to golden color
  100% { color:green; }    // Flash back to white
`;

// Styled component for vibrating and flashing bell
const VibratingBell = styled(FaBell)`
  color: ${(props) => (props.isOpen ? "#00ff00" : "green")};
  animation: ${(props) =>
    props.isOpen
      ? css`
          ${vibrate} 1s infinite, ${flash} 1s ease-in-out infinite;
        `
      : css`
          ${vibrate} 1s infinite;
        `};
  margin: 5px;
`;

// Styled component for status text
const StatusText = styled.div`
  color: ${(props) => (props.isOpen ? "green" : "red")};
  animation: ${(props) =>
    props.isOpen
      ? css`
          ${flash} 2s ease-in-out infinite;
        `
      : css`
          none
        `};
`;

function getOpeningStatus(locations) {
  const now = new Date();
  const currentDay = now.getDay();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();

  return locations.map((location) => {
    let openingHour = 9; // 9 AM
    let closingHour = 18; // 5 PM

    // Special Sunday hours for specific locations
    if (currentDay === 0) {
      // Sunday
      if (location.town === "KAIKOURA") {
        openingHour = 12;
        closingHour = 16;
      } else if (location.town === "PICTON") {
        openingHour = 11.75; // 11:45 AM (11.75 hours)
        closingHour = 16;
      }
    }

    if (currentHour >= openingHour && currentHour < closingHour) {
      return {
        ...location,
        status: (
          <div
            style={{
              display: "flex",
              flex: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <VibratingBell isOpen={true} />
            <StatusText isOpen={true}>Open</StatusText>
            &nbsp;until&nbsp;{closingHour}:00
          </div>
        ),
      };
    } else {
      let hoursUntilOpen;
      if (currentHour >= closingHour) {
        hoursUntilOpen = 24 - currentHour + openingHour;
      } else {
        hoursUntilOpen = openingHour - currentHour;
      }
      const minutesUntilOpen = hoursUntilOpen * 60 - currentMinute;

      return {
        ...location,
        status: (
          <div
            style={{
              display: "flex",
              flex: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "3px",
            }}
          >
            <FaBellSlash isOpen={false} color="red" />
            <StatusText isOpen={false}>Closed.&nbsp;</StatusText>open at&nbsp;
            <StatusText>
              {openingHour === 11.75 ? "11:45" : `${openingHour}:00`}
            </StatusText>
          </div>
        ),
      };
    }
  });
}

function Location() {
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    setLocations(getOpeningStatus(LocationData));
  }, []);

  // console.log({ locations });

  const buttonVariants = {
    hover: {
      scale: 1.05,
      color: "#fff",
      boxShadow: "0px 0px 8px white",
      transition: {
        duration: 0.3,
        yoyo: Infinity,
      },
    },
  };

  return (
    <Section
      inverse
      padding="0rem"
      smPadding="20px"
      id="location"
      minHeight="100vh"
      style={{ background: `url(${background})` }}
    >
      <ContentHeading>Our Locations</ContentHeading>
      <FooterRow>
        {locations.map((el, index) => (
          <FooterColumn
            key={index}
            style={{ background: `linear-gradient(180deg, #fed693, #ca7100)` }}
          >
            <FooterRowAdd>
              <ImgLocation src={el.img} />
              <TextWrapper weight="500" size="1.4rem" mb="0rem" align="center">
                {el.town}
              </TextWrapper>

              <TextWrapper
                weight="600"
                size="1.3rem"
                mb="1rem"
                color="#ca7100"
                align="center"
              >
                {el.title}
              </TextWrapper>

              <TextWrapper
                weight="300"
                size="1rem"
                color="#fff"
                mb="0.5rem"
                align="center"
              >
                {el.status}
              </TextWrapper>

              {el.links.map((link, linkIndex) => (
                <TextWrapper key={linkIndex} align="center">
                  {link}
                </TextWrapper>
              ))}
            </FooterRowAdd>

            <FooterRowAdd2>
              <TextWrapper
                weight="600"
                mb="0rem"
                color="#ca7100"
                style={{ textAlign: "center" }}
              >
                Call us:{" "}
                <a
                  href={el.tel_link}
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  {el.tel_no}
                </a>
              </TextWrapper>
              <TextWrapper
                weight="300"
                mb="1rem"
                color="#ca7100"
                style={{ textAlign: "center" }}
              >
                <a
                  href={el.email_link}
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  {el.email}
                </a>
              </TextWrapper>
            </FooterRowAdd2>
            <FooterSocialIcon
              key={index}
              href={el.fb}
              target="_blank"
              aria-label={el.name}
              mt="0.5rem"
            >
              {el.icon}
            </FooterSocialIcon>
            <ContentButton
              variants={buttonVariants}
              whileHover="hover"
              type="submit"
              inverse
              onClick={() => window.open(el.fresha, "_blank")}
            >
              BOOK NOW
            </ContentButton>
          </FooterColumn>
        ))}
      </FooterRow>
    </Section>
  );
}

export default Location;
