import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Column,
  MainHeading,
  Row,
  TextWrapper,
  TextWrapperHero,
  MainHeading_nail,
  Container,
} from "../../globalStyles";
import { BiPlayCircle } from "react-icons/bi";
import { HeroSection, ButtonContainer, HeroButton } from "./HeroStyles";
import {
  FooterColumn,
  FooterColumnHero,
  FooterRow,
  FooterRowHero,
} from "../Footer/FooterStyles";
import { OperhourData, footerData } from "../../data/footerData";
import background from "./nail_hero.jpg";
import "./buttonflash.css";
import "./textbg.css";
import { fresha_url } from "../../fresha";
import { NavLinks } from "../Navbar/NavbarStyles";

const Hero = () => {
  return (
    <HeroSection id="hero">
      <Row
        justify="center"
        align="center"
        height="100%"
        padding="0rem"
        minHeight="80vh"
      >
        <Column align="center" mb="0%">
          <div class="text-container">
            <MainHeading_nail>Where Quality Meets Efficiency </MainHeading_nail>
          </div>
          <TextWrapper
            color="white"
            size="clamp(1.2rem,3vw,1.5rem)"
            margin="1rem"
            lineHeight="1.1rem"
            // maxWidth="480px"
            align="center"
            padding="1rem"
          >
            Here you will be greeted by our team of experts who are here to
            provide you with a professional experience.
          </TextWrapper>
          <TextWrapper
            color="white"
            mb="1.2rem"
            smSize="1.2rem"
            size="clamp(1.2rem,3vw,1.5rem)"
          >
            Call us:{" "}
            <a
              href="tel: +6435784890"
              style={{ color: "#d4af37", textDecoration: "none" }}
            >
              +64 3578 4890{" "}
            </a>
          </TextWrapper>
          <TextWrapper
            color="white"
            mb="1.2rem"
            smSize="1.2rem"
            size="clamp(1.2rem,3vw,1.5rem)"
          >
            Email us:{" "}
            <a
              href="mailto:diamondnailsblenheim@gmail.com"
              style={{ color: "#d4af37", textDecoration: "none" }}
            >
              diamondnailsblenheim@gmail.com{" "}
            </a>
          </TextWrapper>
          <ButtonContainer>
            <ButtonContainer>
              <NavLinks
                spy={true}
                duration={500}
                smooth={true}
                exact="true"
                offset={-80}
                to="location"
                // onClick={closeMobileMenu}
              >
                <div className="heroneon">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  BOOK NOW
                </div>
              </NavLinks>
            </ButtonContainer>
          </ButtonContainer>
          {/* <FooterRow>


            {OperhourData.map((el, index) => (
              <FooterColumn gap="0rem" key={index}>
                <TextWrapper weight="300" size="1rem" mb="0.2rem">
                  {el.title}
                </TextWrapper>
                <TextWrapper weight="600" size="1.2rem" mb="0.8rem">
                  {el.title2}
                </TextWrapper>
                {el.links.map((link, linkIndex) => (
                  <TextWrapper key={linkIndex} size="1rem" mb="0rem">
                    {link}
                  </TextWrapper>
                ))}
              </FooterColumn>
            ))}
          </FooterRow> */}
        </Column>
      </Row>
    </HeroSection>
  );
};

export default Hero;
