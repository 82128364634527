import React, { useState, useEffect } from "react";
import "./Nails.css";
import items from "./dataNails";
import Menu from "./Menu";
import Categories from "./Categories";
import background from "./light-yellow.jpg";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {
  ContentSec,
  ContentRow,
  TextWrapper,
  ContentButton,
  ContentColumn,
} from "./ContentStyles";
import { Container } from "../../globalStyles";
import { fresha_url } from "../../fresha";
import { NavLinks } from "../Navbar/NavbarStyles";
import { darkYellow, lightYellow } from "../../Colors";

const allCategories = [...new Set(items.map((item) => item.category)), "all"];

const Nails = () => {
  const initial = {
    scale: 0.2,
    y: 40,
    opacity: 0,
  };

  const animation = useAnimation();

  const { ref, inView } = useInView({
    threshold: 0.4,
  });

  useEffect(() => {
    if (inView) {
      animation.start({
        scale: 1,
        y: 0,
        opacity: 1,
      });
    }
  }, [inView, animation]);
  const transition = { delay: 0.3, duration: 0.6 };
  // setInitial Items
  const [initialItems, setInitialItems] = useState(
    items.filter((item) => item.category === items[0].category)
  );
  const [menuItems, setMenuItems] = useState(initialItems);
  const [activeCategory, setActiveCategory] = useState(items[0].category);
  const [categories, setCategories] = useState(allCategories);

  const buttonVariants = {
    hover: {
      scale: 1.05,
      color: "#fff",
      boxShadow: "0px 0px 8px white",
      transition: {
        duration: 0.3,
        yoyo: Infinity,
      },
    },
  };

  const filterItems = (category) => {
    setActiveCategory(category);
    if (category === "all") {
      setMenuItems(items);
      return;
    }
    const newItems = items.filter((item) => item.category === category);
    setMenuItems(newItems);
  };
  return (
    <main id="nails" style={{ backgroundImage: `url(${background})` }}>
      <section className="menu section">
        <div className="title">
          <h2>Nails Service</h2>
        </div>
        <Categories
          categories={categories}
          activeCategory={activeCategory}
          filterItems={filterItems}
        />
        <Menu items={menuItems} />
      </section>
      <ContentSec ref={ref} style={{ backgroundImage: `url(${background})` }}>
        <Container>
          <ContentRow>
            <ContentColumn>
              <TextWrapper>
                <ContentButton
                  initial={initial}
                  transition={{ ...transition, delay: 0.3 }}
                  animate={animation}
                >
                  <NavLinks
                    spy={true}
                    duration={500}
                    smooth={true}
                    exact="true"
                    offset={-80}
                    to="location"
                    style={{ color: darkYellow }}
                  >
                    BOOK NOW
                  </NavLinks>
                </ContentButton>
              </TextWrapper>
            </ContentColumn>
          </ContentRow>
        </Container>
      </ContentSec>
    </main>
  );
};

export default Nails;
