export default function validateForm({
  name,
  email,
  mobile,
  message,
  selectedBeautyServices,
  selectedNailServices,
  selectedDate,
  selectedTime,
}) {
  // Validate name
  if (!name.trim()) {
    return "Name required";
  }

  // Validate email using a regex pattern
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!email) {
    return "Email required";
  } else if (!emailRegex.test(email.toLowerCase())) {
    return "Email address is invalid";
  }

  // Validate mobile: check if provided, numeric and of a minimum length
  if (!mobile) {
    return "Mobile is required";
  } else if (!/^\d+$/.test(mobile)) {
    return "Mobile must be a valid number";
  } else if (mobile.length < 6) {
    return "Mobile needs to be 6 characters or more";
  }

  // Validate message
  if (!message.trim()) {
    return "Message is required";
  }

  if (!selectedDate) {
    return "Date is required";
  }

  if (!selectedTime) {
    return "Preferred Time is required";
  }

  if (
    (!selectedNailServices || selectedNailServices.length === 0) &&
    (!selectedBeautyServices || selectedBeautyServices.length === 0)
  ) {
    return "Please select at least one nail or beauty service";
  }

  // If all validations pass, return null indicating no errors
  return null;
}
